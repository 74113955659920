<template>
  <div class="app-loader" :style="{ display: authenticationChecked ? 'none' : 'block' }">
    <div class="loader">
      <div class="spinner">
        <span class="fa-spin">
          <img src="../assets/img/sync.svg" width="48" height="48">
        </span>
      </div>
      <div class="loader-bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    authenticationChecked: Boolean
  }
}
</script>