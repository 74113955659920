<template>
  <div id="app">
    <Loader :authenticationChecked="authenticationChecked" />
    <div class="server-error" style="display: none" :style="{ display: serverError && serverError.error && serverError.error.errorMessage ? 'block' : 'none' }">
      <p>{{ serverError && serverError.error ? serverError.error.errorMessage : '' }}</p></div>
    <div class="not-authenicated" style="display: none" :style="{ display: authenticationChecked && !authenticated && !serverError ? 'block' : 'none' }">
      <p>Authentication failed. Please contact administrator.</p>
    </div>
    <div class="incomplete-filters" style="display: none" :style="{ display: incompleteFilters ? 'block' : 'none' }">
      <p>Filters are not available. Please contact administrator.</p>
    </div>
    <div class="app-box" style="display: none" :style="{ display: loaded && authenticationChecked && authenticated && !incompleteFilters ? 'flex' : 'none' }">
      <Filters
        :selectedPosition="selectedPosition"
        :positions="positions"
        :updateSelectedPosition="updateSelectedPosition"
        :selectedRegion="selectedRegion"
        :updateSelectedRegion="updateSelectedRegion"
        :selectedClassification="selectedClassification"
        :classifications="classifications"
        :updateSelectClassification="updateSelectClassification"
        :availableMonths="availableMonths"
        :startMonth="startMonth"
        :updateStartMonth="updateStartMonth"
        :endMonth="endMonth"
        :updateEndMonth="updateEndMonth"
        :regions="regions"
        :reset="reset"
        :fetchResults="fetchResults"
        :hasFilters="hasFilters"
        :incompleteFilters="incompleteFilters"
      />
      <Results :results="results" :showSpinner="showSpinner" :showError="showError" :incompleteFilters="incompleteFilters" :filterResultError="filterResultError" />
      <Footer :lastQuery="lastQuery" :results="results" :download="download" :incompleteFilters="incompleteFilters" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from './components/Loader.vue'
import Filters from './components/Filters.vue'
import Results from './components/Results.vue'
import Footer from './components/Footer.vue'

const USER_API = `${ process.env.VUE_APP_SERVER_URI }/api/fetch-user`
const RESULTS_API = `${ process.env.VUE_APP_SERVER_URI }/api/results`
const DOWNLOAD_API = `${ process.env.VUE_APP_SERVER_URI }/api/download`
const CELL_RANK = 'rank'
const CELL_NAME = 'name'
const CELL_SCORE = 'miles'

export default {
  name: 'App',
  components: {
    Loader,
    Filters,
    Results,
    Footer
  },
  data() {
    return {
      authenticationChecked: false,
      authenticated: false,
      pin: null,
      domain: null,
      random: null,
      sso_token: null,
      setupData: null,
      loaded: false,
      currentYear: null,
      positions: [],
      selectedPosition: null,
      regions: [],
      selectedRegion: null,
      classifications: [],
      selectedClassification: null,
      selectedClassificationLocked: false,
      availableMonths: [],
      startMonth: null,
      endMonth: null,
      results: null,
      showSpinner: false,
      lastQuery: null,
      showError: false,
      axiosCancel: null,
      incompleteFilters: false,
      serverError: null,
      filterResultError: null
    }
  },
  methods: {
    fetchResults(settings) {
      if(this.axiosCancel) {
        this.axiosCancel('user cancelled request')
      }

      this.showError = false
      this.showSpinner = true
      this.lastQuery = null

      const queryObj = {}
      let isValid = true
      this.filterResultError = null

      if(this.selectedPosition) {
        queryObj['position'] = this.selectedPosition
      } else {
        isValid = false
      }

      if(this.selectedRegion) {
        queryObj['region'] = this.selectedRegion
      } else {
        isValid = false
      }

      if(this.selectedClassification) {
        queryObj['classification'] = this.selectedClassification
      } else {
        isValid = false
      }

      if(this.startMonth || this.startMonth === 0) {
        queryObj['from'] = `${ this.currentYear }-${ (this.startMonth + 1) < 10 ? '0' + (this.startMonth + 1) : (this.startMonth + 1) }`
      } else {
        isValid = false
      }

      if(this.endMonth || this.endMonth === 0) {
        queryObj['to'] = `${ this.currentYear }-${ (this.endMonth + 1) < 10 ? '0' + ( this.endMonth + 1 ) : (this.endMonth + 1) }`
      } else {
        isValid = false
      }

      if(!isValid) {
        this.results = null
        this.showSpinner = false
        return false
      }

      if(queryObj) {
        const app = this
        const CancelToken = axios.CancelToken

        const isDownload = settings && settings.isDownload

        axios({
          method: 'post',
          url: `${ isDownload ? DOWNLOAD_API : RESULTS_API }`,
          data: {
            pin: this.pin,
            domain: this.domain,
            random: this.random,
            sso_token: this.sso_token,
            searchParams: queryObj
          },
          cancelToken: new CancelToken(function executor(cancel) {
            app.axiosCancel = cancel;
          })
        }).then((res) => {
          if(isDownload) {
            if(!res.data) {
              throw {
                statusCode: "Error",
                error: {
                  errorCode: 500,
                  errorMessage: "Failed to generate CSV"
                }
              }
            }

            const { fileName, data } = res.data
            if(fileName && data) {
              //unable to launch CSV download with POST
              //fails to validate with GET
              const hiddenElement = document.createElement('a');
              hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
              hiddenElement.target = '_blank';
              hiddenElement.download = fileName;
              hiddenElement.click();
            } else {
              throw {
                statusCode: "Error",
                error: {
                  errorCode: 400,
                  errorMessage: "Incomplete data"
                }
              }
            }
          } else {
            //display -- not download
            if(res.data) {
              this.results = res.data
                .sort((a, b) => {
                  if(a[CELL_RANK] > b[CELL_RANK]) return 1
                  if(a[CELL_RANK] < b[CELL_RANK]) return -1
                  return 0
                })
                .map(entry => {
                  const person = {}
                  person.rank = entry[CELL_RANK] ? entry[CELL_RANK] : ''
                  person.name = entry[CELL_NAME] ? entry[CELL_NAME] : ''
                  person.score = entry[CELL_SCORE] || entry[CELL_SCORE] === 0 ? entry[CELL_SCORE].toString() : ''
                  return person
                })
            }
          }

          this.lastQuery = queryObj
        }).catch(ex => {
          this.results = null
          this.showError = true

          if(ex.response?.status === 500) {
            this.filterResultError = {
              statusCode: "Error",
              error: {
                errorCode: 500,
                errorMessage: ex.response.data
              }
            }
          } else if(ex.response?.status === 404) {
            this.filterResultError = {
              statusCode: "Error",
              error: {
                errorCode: 404,
                errorMessage: "Resource not found"
              }
            }
          } else if(ex.response?.data) {
            this.filterResultError = ex.response.data
          } else {
            //unknown
            console.error({ ex })
          }
        }).then(() => {
          this.showSpinner = false
        })
      } else {
        this.results = null
        this.showSpinner = false
      }
    },
    fieldUpdated() {
      this.lastQuery = null
    },
    reset() {
      this.selectedPosition = null
      this.selectedRegion = null
      if(!this.selectedClassificationLocked) {
        this.selectedClassification = null
      }
      this.startMonth = null,
      this.endMonth = null
    },
    updateStartMonth(index) {
      if(index || index === 0) {
        if((this.endMonth || this.endMonth === 0) && this.endMonth < index) {
          this.updateEndMonth(null)
        }
        this.startMonth = index
      } else {
        this.startMonth = null
      }
    },
    updateEndMonth(index) {
      if(index || index === 0) {
        this.endMonth = index
      } else {
        this.endMonth = null
      }
    },
    updateSelectedPosition(key) {
      this.selectedPosition = key
    },
    updateSelectedRegion(key) {
      this.selectedRegion = key
    },
    updateSelectClassification(key) {
      if(this.selectedClassification === key) {
        this.selectedClassification = null
      } else {
        this.selectedClassification = key
      }
    },
    download() {
      // let queryStr = ''
      // for(let queryParam in this.lastQuery) {
      //   queryStr += `${ queryParam }=${ this.lastQuery[queryParam] }&`
      // }
      // if(queryStr.length) {
      //   queryStr = queryStr.substring(0, queryStr.length - 1)
      // }
      // window.open(`${ DOWNLOAD_API }?pin=${ this.pin }&domain=${ this.domain }&random=${ this.random }&sso_token=${ this.sso_token }&${ queryStr }`, '_blank');
      this.fetchResults({isDownload: true})
    }
  },
  watch: {
    selectedPosition: {
      handler: function(val, oldVal) {
        if(!(val === oldVal)) {
          this.fieldUpdated()
        }
      }
    },
    selectedRegion: {
      handler: function(val, oldVal) {
        if(!(val === oldVal)) {
          this.fieldUpdated()
        }
      }
    },
    selectedClassification: {
      handler: function(val, oldVal) {
        if(!(val === oldVal)) {
          this.fieldUpdated()
        }
      }
    },
    startMonth: {
      handler: function(val, oldVal) {
        if(!((val === 0 && oldVal === 0) || val === oldVal)) {
          this.fieldUpdated()
        }
      }
    },
    endMonth: {
      handler: function(val, oldVal) {
        if(!((val === 0 && oldVal === 0) || val === oldVal)) {
          this.fieldUpdated()
        }
      }
    }
  },
  computed: {
    hasFilters() {
      return !(this.selectedPosition || this.selectedRegion || this.selectedClassification || (this.startMonth || this.startMonth === 0) || (this.endMonth || this.endMonth === 0))
    }
  },
  created: async function() {
    const paramData = {};
    const iframeParams = window.location.search;
    if(iframeParams && iframeParams.length) {
      iframeParams.substring(1).split('&').forEach(pairStr => {
        const nameValue = pairStr.split('=');
        if(nameValue.length) {
          paramData[nameValue[0].toLowerCase()] = nameValue.length > 1 ? nameValue[1] : null
        }
      });
    }

    this.pin = paramData['pin']
    if(!this.pin) {
      this.authenticationChecked = true
      return;
    }

    this.domain = paramData['domain']
    this.random = paramData['random']
    this.sso_token = paramData['sso_token']

    await fetch(`${ USER_API }`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        pin: this.pin,
        domain: this.domain,
        random: this.random,
        sso_token: this.sso_token
      })
    })
    .then((res) => {
      if(res.status === 404) {
        throw {
          statusCode: "Error",
          error: {
            errorCode: 404,
            errorMessage: "Resource not found"
          }
        }
      }
      if(res.status === 401) {
        throw {
          statusCode: "Error",
          error: {
            errorCode: res.status,
            errorMessage: res.statusText
          }
        }
      }
      return res.json()
    })
    .then(res => {
      if(res.statusCode === 'Error') {
        throw res
      }

      if(res.error === 'Not found') {
        this.setupData = {}
        this.incompleteFilters = true
      } else {
        this.setupData = res
      }
      this.authenticated = true
    })
    .catch(ex => {
      if(ex.error) {
        this.serverError = ex
      } else {
        //unknown
        console.error({ ex })
      }
    })

    this.authenticationChecked = true

    if(!this.authenticated) return;

    //set up data
    const today = new Date()
    this.currentYear = today.getFullYear()
    let currentMonth = today.getMonth()
    const availableMonths = []
    while(currentMonth > -1) {
      availableMonths.push(currentMonth)
      currentMonth--
    }
    this.availableMonths.push(...availableMonths.reverse())

    const positions = this.setupData.position ? [...this.setupData.position] : []
    this.positions.push(...positions)

    const regions = this.setupData.region ? [...this.setupData.region] : []

    this.regions.push(...regions)

    const classifications = [
      { label: 'Metro', value: 'Metro' },
      { label: 'Rural', value: 'Rural' }
    ]

    if(this.setupData.classification && this.setupData.classification.length && this.setupData.classification.length === 1) {
      const presetClassification = this.setupData.classification[0]
      const matches = classifications.filter(classification => classification.value === presetClassification)
      if(matches.length) {
        classifications.forEach(classification => {
          classification.disabled = true
          if(classification.value === presetClassification) {
            classification.checked = true
            this.selectedClassification = presetClassification
            this.selectedClassificationLocked = true
          }
        })
      }
    }

    this.classifications.push(...classifications)

    this.loaded = true
  }
}
</script>