<template>
  <div class="col-md-auto">
    <div class="form-group">
      <span class="label">Classification</span>
      <div class="inline-options">
        <div class="inline-option" v-for="(option, index) in classifications" :class="{ active: option.value === selectedClassification, disabled: option.disabled }" :key="`classification-${ index }`">
          <input type="checkbox" :id="'field-classification-' + index" :checked="option.value === selectedClassification" @click="updateSelectClassification(option.value)" :disabled="option.disabled">
          <label :for="'field-classification-' + index">{{ option.label }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassificationFilter',
  props: {
    selectedClassification: {
      type: String,
      required: false
    },
    classifications: Array,
    updateSelectClassification: Function
  }
}
</script>