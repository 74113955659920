<template>
  <div class="col-md-auto">
    <div class="form-group">
      <label for="dropdownMenuToDate" class="label">To date</label>
      <div class="dropdown">
        <button class="btn-dropdown" :class="{ 'is-empty': !(endMonth === 0 || endMonth) }" type="button" id="dropdownMenuToDate" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="text" v-if="endMonth || endMonth === 0">{{ getMonthName(endMonth) }} {{ currentYear }}</span>
          <span class="text" v-else>Please Select</span>
          <span class="icon"><img src="../../assets/img/caret-down.svg" alt="" width="14" height="14"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuToDate">
          <Simplebar>
            <button type="button" class="dropdown-item" :class="{ active: ((month === 0 && endMonth === 0) || month === endMonth) }" v-for="(month, index) in availableMonths" @click="updateEndMonth(index)" :disabled="(startMonth || startMonth > 0) && (month < startMonth)" :key="`end-month-${ index }`">{{ getMonthName(month) }} {{ currentYear }}</button>
          </Simplebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue'

export default {
  name: 'EndMonthFilter',
  components: {
    Simplebar
  },
  props: {
    startMonth: Number,
    endMonth: Number,
    availableMonths: Array,
    updateEndMonth: Function,
    getMonthName: Function
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>