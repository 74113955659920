<template>
  <div class="col-md-auto">
    <div class="form-group">
      <label for="dropdownMenuPosition" class="label">Position</label>
      <div class="dropdown">
        <button class="btn-dropdown" :class="{ 'is-empty': !selectedPosition }" type="button" id="dropdownMenuPosition" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="text">{{ selectedPosition ? selectedPosition : 'Please Select' }}</span>
          <span class="icon"><img src="../../assets/img/caret-down.svg" alt="" width="14" height="14"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Simplebar>
            <button type="button" class="dropdown-item" :class="{ active: position === selectedPosition }" v-for="(position, index) in positions" @click="updateSelectedPosition(position)" :key="`position-${ index }`">{{ position }}</button>
          </Simplebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue'

export default {
  name: 'PositionFilter',
  components: {
    Simplebar
  },
  props: {
    selectedPosition: String,
    positions: Array,
    updateSelectedPosition: Function
  }
}
</script>