<template>
  <section class="app-content" v-if="!incompleteFilters">
    <div class="container-fluid">
      <div class="results">
        <div class="loader" v-show="showSpinner">
          <div class="spinner">
            <span class="fa-spin">
              <img src="../assets/img/sync.svg" alt="" width="48" height="48">
            </span>
          </div>
          <div class="loader-bg"></div>
        </div>
        <div class="results-table" v-if="results && results.length > 0 && visible">
          <p class="info"><img src="../assets/img/info-circle.svg" alt="" width="14" height="14"> Full leaderboard - scroll within the table to see more entries</p>
          <div class="results-table-header">
            <div class="row">
              <div class="col-auto">Rank</div>
              <div class="col-auto">Name</div>
              <div class="col-auto">Miles</div>
            </div>
          </div>
          <div class="scroll-wrapper">
            <div class="content-scroll">
              <Simplebar>
                <div class="results-table-body">
                  <div class="row" v-for="(person, index) in results" :key="`person-${ index }`">
                    <div class="col-auto">{{ person.rank }}</div>
                    <div class="col-auto">{{ person.name }}</div>
                    <div class="col-auto">{{ person.score }}</div>
                  </div>
                </div>
              </Simplebar>
            </div>
          </div>
        </div>
        <div class="no-results" v-if="results && results.length === 0">
          <div class="no-results-box">
            <p>No results found</p>
          </div>
        </div>
        <div class="no-results" v-if="!results">
          <div class="no-results-box" v-if="!showError">
            <p>Use the filters above to view a leaderboard</p>
          </div>
          <div class="no-results-box" v-if="showError">
            <p>{{ filterResultError && filterResultError.error ? filterResultError.error.errorMessage : 'Something went wrong. Please try again.' }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Simplebar from 'simplebar-vue'

export default {
  name: 'Results',
  data() {
    return {
      visible: Boolean
    }
  },
  components: {
    Simplebar
  },
  props: {
    results: Array,
    showSpinner: Boolean,
    showError: Boolean,
    incompleteFilters: Boolean,
    filterResultError: Object
  },
  watch: {
    results: function() {
      //resize Simplebar
      this.visible = false
      setTimeout(() => {
        this.visible = true
      }, 50)
    }
  }
}
</script>