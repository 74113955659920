<template>
  <header class="app-header">
    <div class="container-fluid">
      <h1>Leaderboard</h1>
      <p>Use the filters to view a leaderboard</p>
      <div class="row">
        <PositionFilter :selectedPosition="selectedPosition" :positions="positions" :updateSelectedPosition="updateSelectedPosition" />
        <RegionFilter :selectedRegion="selectedRegion" :regions="regions" :updateSelectedRegion="updateSelectedRegion" />
        <ClassificationFilter :selectedClassification="selectedClassification" :classifications="classifications" :updateSelectClassification="updateSelectClassification" />
      </div>
      <div class="row">
        <StartMonthFilter :availableMonths="availableMonths" :startMonth="startMonth" :updateStartMonth="updateStartMonth" :getMonthName="getMonthName" />
        <EndMonthFilter :availableMonths="availableMonths" :startMonth="startMonth" :endMonth="endMonth" :updateEndMonth="updateEndMonth" :getMonthName="getMonthName" />
        <div class="col-md-auto">
          <div class="button-group">
            <button type="button" class="btn btn-outline-primary" @click="fetchResults()">Apply</button>
            <button type="button" class="btn-reset" @click="reset()" v-bind:disabled="hasFilters">Clear</button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import PositionFilter from './filters/Position.vue'
import RegionFilter from './filters/Region.vue'
import ClassificationFilter from './filters/Classification.vue'
import StartMonthFilter from './filters/StartMonth.vue'
import EndMonthFilter from './filters/EndMonth.vue'

const MONTHS = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default {
  name: 'Filters',
  props: {
    availableMonths: Array,
    selectedPosition: String,
    positions: Array,
    updateSelectedPosition: Function,
    selectedRegion: String,
    updateSelectedRegion: Function,
    selectedClassification: String,
    classifications: Array,
    updateSelectClassification: Function,
    startMonth: Number,
    updateStartMonth: Function,
    endMonth: Number,
    updateEndMonth: Function,
    regions: Array,
    reset: Function,
    fetchResults: Function,
    hasFilters: Boolean
  },
  components: {
    PositionFilter,
    RegionFilter,
    ClassificationFilter,
    StartMonthFilter,
    EndMonthFilter
  },
  methods: {
    getMonthName(monthIndex) {
      return (monthIndex === 0 || monthIndex) && MONTHS.length > monthIndex ? MONTHS[monthIndex] : '';
    }
  }
}
</script>