<template>
  <div class="col-md-auto">
    <div class="form-group">
      <label for="dropdownMenuRegion" class="label">Region</label>
      <div class="dropdown">
        <button class="btn-dropdown" :class="{ 'is-empty': !selectedRegion }" type="button" id="dropdownMenuRegion" data-toggle="dropdown" data-flip="false" aria-haspopup="true" aria-expanded="false">
          <span class="text">{{ selectedRegion ? selectedRegion : 'Please Select' }}</span>
          <span class="icon"><img src="../../assets/img/caret-down.svg" alt="" width="14" height="14"></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuRegion">
          <simplebar>
            <button type="button" class="dropdown-item" :class="{ active: region === selectedRegion }" v-for="(region, index) in regions" @click="updateSelectedRegion(region)" :key="`region-${ index }`">{{ region }}</button>
          </simplebar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue'

export default {
  name: 'RegionFilter',
  components: {
    Simplebar
  },
  props: {
    selectedRegion: String,
    regions: Array,
    updateSelectedRegion: Function
  }
}
</script>